


















import { Vue, Component } from "vue-property-decorator";
import { mdiAlert } from "@mdi/js";

@Component
export default class CamtelYoomeeAlert extends Vue {
    alertIcon: string = mdiAlert;
}
