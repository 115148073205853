



























import { Vue, Component } from "vue-property-decorator";
import { mdiCheckCircle } from "@mdi/js";

@Component
export default class DstvOrderAlert extends Vue {
    alertIcon: string = mdiCheckCircle;
}
