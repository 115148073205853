import { AxiosResponse } from "axios";
import Axios, { ApiFilterOptions } from "./axios";

export interface CreateOrderInput {
    captcha: string;
    payment_method: string;
    email: string;
    phone_number: string;
    dstv_package: string;
    new_dstv_package_id: string | null;
    cost_price: number | null;
    sale_price: number | null;
    smartcard_number: string;
    name_confirmation: boolean;
    referral_code: string;
    months: number;
}

export interface CanalplusOrderRequest {
    captcha: string;
    payment_method: string;
    email: string;
    phone_number: string;
    canalplus_package: string;
    card_number: string;
}

export interface BuyAirtimeInput {
    captcha: string;
    payment_method: string;
    email: string;
    phone_number: string;
    phone_network: string;
    recipient_phone_number: string;
    amount: number;
}

export interface ExchangeOrderRequest {
    captcha: string;
    payment_method: string;
    email: string;
    phone_number: string;
    phone_network: string;
    recipient_phone_number: string;
    amount: number;
}

export interface EneoOrderRequest {
    captcha: string;
    payment_method: string;
    phone_number: string;
    email: string;
    bill_number: string | null;
    meter_type: string;
    meter_number: string | null;
    amount: number | null;
}

export interface CamwaterOrderRequest {
    captcha: string;
    payment_method: string;
    phone_number: string;
    email: string;
    bill_number: string | null;
}

export interface SellCryptoOrderRequest {
    captcha: string;
    phone_number: string;
    email: string;
    amount: number;
}

class Order {
    private axios: typeof Axios;

    constructor(axios: typeof Axios) {
        this.axios = axios;
    }

    adminIndex(options: ApiFilterOptions): Promise<AxiosResponse> {
        return this.axios.get("/admin/orders", {
            params: options,
        });
    }

    adminMaintain(orderId: string): Promise<AxiosResponse> {
        return this.axios.post(`/admin/maintainers/orders/${orderId}`);
    }

    adminRegisterFailedPayment(orderId: string): Promise<AxiosResponse> {
        return this.axios.post(
            `/admin/maintainers/register-failed-payment/${orderId}`
        );
    }

    adminRegisterSuccessfulPayment(orderId: string): Promise<AxiosResponse> {
        return this.axios.post(
            `/admin/maintainers/register-successfull-payment/${orderId}`
        );
    }

    index(): Promise<AxiosResponse> {
        return this.axios.get("/orders");
    }

    create(order: CreateOrderInput): Promise<AxiosResponse> {
        return this.axios.post(`/orders/`, order);
    }

    buyAirtime(input: BuyAirtimeInput): Promise<AxiosResponse> {
        return this.axios.post(`/orders/buy-airtime`, input);
    }

    createCanalplus(input: CanalplusOrderRequest): Promise<AxiosResponse> {
        return this.axios.post(`/orders/canalplus`, input);
    }

    createExchange(input: ExchangeOrderRequest): Promise<AxiosResponse> {
        return this.axios.post(`/orders/exchange`, input);
    }

    createEneo(input: EneoOrderRequest): Promise<AxiosResponse> {
        return this.axios.post(`/orders/eneo`, input);
    }

    createCamwater(input: CamwaterOrderRequest): Promise<AxiosResponse> {
        return this.axios.post(`/orders/camwater`, input);
    }

    createCryptoSell(input: SellCryptoOrderRequest): Promise<AxiosResponse> {
        return this.axios.post(`/orders/crypto-sell`, input);
    }

    get(orderId: string): Promise<AxiosResponse> {
        return this.axios.get(`/orders/${orderId}`);
    }
}

export default new Order(Axios);
